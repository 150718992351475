import React, {Fragment} from 'react'
import styled from 'styled-components';
import {connect} from 'react-redux';
import queryString from 'query-string';

import Table from './table'
import FiltersWrapper from '../filters'
import Pagination from './pagination'
import SearchInput from '../ui/SearchInput'
import FilterListIcon from '@material-ui/icons/FilterList';

import {black600, white} from '../../theme/colors'
import _debounce from 'lodash.debounce';
import {TOGGLE_FILTER} from '../../helpers/constants/actions';
import IconWithRedBubble from '../ui/IconWithRedBubble';
import {withRouter} from 'react-router-dom';
import appendToSearch from '../../helpers/utils/appendToSearch';
import ContentWrapper from '../styles/ContentWrapper';
import {baseCaption} from '../../theme/typography';
import Loader from '../Loader';
import {isMobileScreen} from "../../helpers/isMobileScreen";

const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
  margin-top: 24px;
  margin-bottom: 24px;
`

const Content = styled.div`
  margin: ${props => props.margin || '24px'};
  position: relative;
  width: 100%;
  max-width: 100%;
  max-height: ${props => props.maxHeight ? props.maxHeight : ""};

  display: flex;
  border-radius: 4px;
  box-shadow: 0px 10px 24px rgba(91, 127, 220, 0.16);
`

const BlankScreen = styled(Content)`
  min-height: ${props =>
          props.count && !props.shouldRestrictHeight
                  ? `${(props.count + 1) * 64}px`
                  : `calc(${window.innerHeight}px - (64px + 64px + 64px + 48px));`};
  width: ${props => props.width ? `${props.width}px` : '100%'};
  max-width: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: ${white};
`
const ShowNumberOfRecords = styled.p`
  ${baseCaption}
  color: ${black600};
`;

const Loading = styled(Loader)`
  position: relative;
  display: flex;
  justify-content: center;
  left: 0;
  top: 0;
`;

class ReactTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pageSize: 15,
      searchTerm: props.searchValue || '',
    }
  }

  componentDidMount = () => {
    this.handleSearchDebounced = _debounce(function () {
      this.props.searchOnChange(this.state.searchTerm);
    }, 100);

    const {location} = this.props;
    if (location.search) {
      const state = {...this.state};

      const qs = queryString.parse(location.search);

      if (qs['page-size']) {
        state.pageSize = parseInt(qs['page-size'], 10);
      }

      this.setState(state);
    }
  }

  /**
   * Clear All filters
   *
   */
  clearFilters = () => {
    const {onClearAllFilter} = this.props;

    this.toggleFilterList();

    if (typeof onClearAllFilter === 'function') {
      onClearAllFilter()
    }
  }

  /**
   * Action to handle Apply button
   *
   * @param {string} key
   * @param {*} value
   */
  applyFilter = () => {
    const {onApplyFitler} = this.props;

    this.toggleFilterList();

    if (typeof onApplyFitler === 'function') {
      onApplyFitler()
    }
  }

  sanitizeFilters() {
    let payload = {};
    this.state.filters || [].forEach(item => {
      payload[item.key] = item.stateValue
    })
    return payload
  }

  nextPage() {
    this.props.onPageChange(
      this.props.nextPage,
      this.sanitizeFilters(),
      this.state.order
    )
  }

  previousPage() {
    this.props.onPageChange(
      this.props.previousPage,
      this.sanitizeFilters(),
      this.state.order
    )
  }

  onSearchChange = e => {
    this.setState({searchTerm: e.target.value})
  }

  onSearchClick = e => {
  this.handleSearchDebounced()   
  }
   handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      this.handleSearchDebounced()   
    }
  }

  onPageSizeChange = ({value}) => {
   
    const {history} = this.props
   
    this.setState({pageSize: value}, () => {
      const {history, location} = this.props;
      console.log("history",history)
      console.log("historyy",value)
      console.log("historyy",location.search)
      console.log("historyyy",history.location.state)
      history.push({
        route: '/',
        search: appendToSearch(location.search, {
          'page-size': value,
          'page': 1
        }),
        state: {...history.location.state},
      });
    });
  }

  toggleFilterList = () => {
    const isShow = !this.state.showFilterList;
    this.setState({showFilterList: isShow});
    if(typeof this.props.filterHandler === 'function'){
      this.props.filterHandler();
    }
    this.props.showFilterAction(isShow);
  }

  componentWillUnmount() {
    this.props.showFilterAction(false);
  }

  renderTable() {
    const {
      data,
      rowHeight,
      columns,
      loading,
      tableStyle,
      order,
      onRowClick,
      onOrderChange,
      fluidTable = false,
      rowActionType = 'VIEW',
      rowActionElement,
      enableCheckbox = false,
      disableRowClick = false,
      rowActionElementWidth,
      tableRightBlockElement,
      maxHeight="500px",
      selectedOutletRows,
      isCursor,
      isPosition,
      isBorder,
      useStatusColor
    } = this.props

    const width = columns.length * 175
    const shouldRestrictHeight =
      false || columns.filter(column => column.fixed).length === 0

    const leftColumns = columns.filter(column => column.fixed === 'left') || [];
    const rightColumns = columns.filter(column => column.fixed === 'right') || [];
    const centerColumns = columns.filter(column => !column.fixed) || [];

    if (loading) {
      return (
        <BlankScreen
          style={tableStyle}
          margin='24px 0'
          shouldRestrictHeight={shouldRestrictHeight}>
          <Loading/>
        </BlankScreen>
      )
    }

    if (!data || data.length === 0) {
      return (
        <BlankScreen
          style={tableStyle}
          margin='24px 0'
          shouldRestrictHeight={shouldRestrictHeight}>
          <p>No Data Available</p>
        </BlankScreen>
      )
    }

    return (
      <Content
        style={tableStyle}
        margin="0"
        shouldRestrictHeight={shouldRestrictHeight}
        maxHeight={maxHeight}
      >
        {leftColumns.length ?
          <Table
            updateOrder={onOrderChange}
            order={order}
            columns={leftColumns}
            position="left"
            data={data}
            useStatusColor={useStatusColor}
            onRowClick={onRowClick}
            rowHeight={rowHeight}
            disableRowClick={disableRowClick}
            isCursor={isCursor}
            isPosition={isPosition}
            isBorder={isBorder}
          />
          : null}
        <Table
          updateOrder={onOrderChange}
          order={order}
          columns={centerColumns}
          data={data}
          onRowClick={onRowClick}
          rowActionType={rowActionType}
          rowActionElement={rowActionElement}
          rowActionElementWidth={rowActionElementWidth}
          enableCheckbox={enableCheckbox}
          fluidTable={centerColumns.length < 4 || fluidTable}
          rowHeight={rowHeight}
          disableRowClick={disableRowClick}
          selectedOutletRows={selectedOutletRows}
          isCursor={isCursor}
          isPosition={isPosition}
          useStatusColor={useStatusColor}

          isBorder={isBorder}
        />
        {rightColumns.length ?
          <Table
            updateOrder={onOrderChange}
            order={order}
            onRowClick={onRowClick}
            columns={rightColumns}
            position="right"
            data={data}
            rowHeight={rowHeight}
            disableRowClick={disableRowClick}
            isCursor={isCursor}
            isPosition={isPosition}
            isBorder={isBorder}
            useStatusColor={useStatusColor}
          />
          : null}
        {tableRightBlockElement}
      </Content>
    )
  }

  render() {
    const {
      current,
      count,
      className,
      total,
      totalPages,
      previousPage,
      nextPage,
      showPagination = true,
      title = '',
      showSearch = false,
      showFilter = false,
      showFilterLoading = false,
      leftHeaderElements = null,
      rightHeaderElements = null,
      filterList = [],
      data,
      filtersApplied,
      showTotalRecordText = false,
      rightHeaderElementsBeforeIcons,
      tableRightBlockElement,
      additionalFilters,
    } = this.props;
    const isMobile = isMobileScreen()
    const {searchTerm, showFilterList, pageSize} = this.state;

    const leftHeader = <ContentWrapper flexFlow="column" margin="0">
      {leftHeaderElements}
    </ContentWrapper>;

    const rightHeader = <ContentWrapper margin="0">
      {rightHeaderElementsBeforeIcons ? rightHeaderElements : null}
      {showSearch ? <SearchInput value={searchTerm} onChange={this.onSearchChange} onClick={this.onSearchClick} onEnter={this.handleKeyDown}/> : null}
      {showFilter ?
        <IconWithRedBubble applied={!showFilterList && filtersApplied} onClick={this.toggleFilterList}><FilterListIcon/></IconWithRedBubble> : null}
      {!rightHeaderElementsBeforeIcons ? rightHeaderElements : null}
    </ContentWrapper>;

    return (
      <Wrapper className={className}>
        {
          leftHeaderElements ?
            <Fragment>
              {title ?
                <ContentWrapper justifyContent="flex-start" margin="0 0 12px">
                  <h3>{title}</h3>
                </ContentWrapper> : null}
              <ContentWrapper
                flexFlow="column"
                justifyContent="space-between"
                alignItems={isMobile ? "stretch" : "flex-end"}
                margin="0 0 24px"
              >
                {leftHeader}
                {rightHeader}
              </ContentWrapper>
            </Fragment>
            :
            <ContentWrapper justifyContent="space-between" margin="0 0 12px">
              <h3>{title}</h3>
              {rightHeader}
            </ContentWrapper>
        }
        {this.renderTable()}
        {showPagination && (data || []).length ? (
          <ContentWrapper flexFlow="column" margin="24px 0" justifyContent={showTotalRecordText ? 'space-between' : 'flex-end'}>
            {showTotalRecordText ?
              <ShowNumberOfRecords>
                Showing {current === 1 ? 1 : ((pageSize * previousPage) + 1)} - {((Math.ceil(pageSize * current)) > total) ? total : (Math.ceil(pageSize * current))} of {total} {total > 1 ? 'Records' : 'Record'}
              </ShowNumberOfRecords>
              : null}
            <ContentWrapper margin='0'>

              <Pagination
                current={current}
                count={(data || []).length}
                totalPages={totalPages || Math.ceil(total / pageSize)}
                onNextPageClick={nextPage ? () => this.nextPage() : null}
                onPrevPageClick={previousPage ? () => this.previousPage() : null}
                pageSize={pageSize}
                onPageSizeChange={this.onPageSizeChange}
              />
            </ContentWrapper>
          </ContentWrapper>
        ) : null}
        <FiltersWrapper
          showLoading={showFilterLoading}
          open={showFilterList}
          closeAction={this.toggleFilterList}
          filterList={filterList}
          onClearAllAction={this.clearFilters}
          onApplyFilterAction={this.applyFilter}
          additionalFilters={additionalFilters}
        />
      </Wrapper>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showFilterAction: (value = false) => {
      dispatch({
        type: TOGGLE_FILTER,
        payload: {
          isFilterOpen: value
        }
      })
    }
  };
};

export default withRouter(connect(null, mapDispatchToProps)(ReactTable));
